<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <!-- logo -->
        <b-link
          :to="{ path: '/' }"
          class="brand-logo"
        >
          <logo />

          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('auth.forgot_password') }}? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('auth.enter_your_email_and_we_will_send_you_instructions_to_reset_your_password') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="resetPassForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <div class="py-1">
                  <b-alert
                    v-if="!!(alert)"
                    show
                    :variant="alertVariant"
                  >
                    <div class="alert-body">
                      <feather-icon
                        :icon="alertVariant==='success'?'CheckCircleIcon':'AlertCircleIcon'"
                        class="mr-50 mt-25"
                      />
                      {{ $t(`auth.${alert}`) }}
                    </div>
                  </b-alert>
                  <!-- submit button -->
                  <b-button
                    variant="primary"
                    block
                    type="submit"
                    :disabled="!!alert || !!errors.length||submittingFetch"
                  >
                    <span
                      v-if="submittingFetch"
                      class="align-middle"
                    >
                      <b-spinner
                        type="grow"
                        small
                      />
                      {{ $t('Loading...') }}
                    </span>
                    <span
                      v-else
                      class="align-middle"
                    >{{ $t('auth.send_reset_link') }}</span>

                  </b-button>
                </div>

              </validation-provider>

            </b-form-group>

          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link
            :to="{name:'auth-login'}"
            class="align-middle"
          >
            <feather-icon icon="ChevronLeftIcon" />{{ $t('auth.Back to login') }}
          </b-link>
        </b-card-text>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { translateApiErrors } from '@/mixins/functions'
import { $themeConfig } from '@themeConfig'
import { showToast } from '@/mixins/notification/toasts'

export default {
  components: {
    Logo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [showToast],
  data() {
    return {
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.resetPassForm.validate().then(success => {
        if (success) {
          this.sendPasswordResetEmail()
        }
      })
    },
    sendPasswordResetEmail() {
      this.submittingFetch = true
      store
        .dispatch('account/sendPasswordResetEmail', this.userEmail)
        .then(response => {
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            this.alertVariant = 'success'
            localStorage.setItem('_userEmail', this.userEmail)
          }
          this.alert = response.data.message
        })
        .catch(error => {
          if (error.response.status === 500) {
            return this.showToast({ status: 'error', statusText: 'Something went wrong' }, 'Email')
          }
          const { errors } = error.response.data
          translateApiErrors(errors)
          return this.$refs.resetPassForm.setErrors(
            errors,
          )
        })
        .finally(() => {
          this.submittingFetch = false
        })
    },
  },
  setup() {
    const userEmail = ref('')
    const alert = ref('')
    const alertVariant = ref('danger')
    const submittingFetch = ref(false)
    const { appName } = $themeConfig.app
    return {
      appName,
      userEmail,
      alert,
      alertVariant,
      submittingFetch,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
